<template>
    <el-dialog
        title="填写今天的观测数据"
        :visible.sync="flag"
        width="50%"
    >
    <el-form 
        ref="form" 
        :model="form" 
        :rules="rules"
        label-width="150px" 
        :inline="true" 
        v-if="showForm"
    >
        <el-form-item label="日期:" prop="date">
            <el-input v-model="form.date" style="width: 300px;" disabled></el-input>
        </el-form-item>
        <el-form-item label="观测员:" prop="observer">
            <el-input v-model="form.observer" style="width: 300px;"></el-input>
        </el-form-item>
        <!-- 上午 -->
        <el-divider content-position="center">上午</el-divider>
        <!-- 上午的观测时间 -->
        <div>
            <el-form-item label="观测时间:" prop="time1">
                <el-time-picker
                    v-model="form.time1"
                    :picker-options="{
                        selectableRange: '06:00:00 - 11:30:00'
                    }"
                    format="HH:mm:ss"
                    value-format="HH:mm:ss"
                    placeholder="选择观测时间">
                </el-time-picker>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="chooseshebei(1)">自动获取</el-button>
            </el-form-item>
        </div>
        <!-- 上午的数据 -->
        <el-form-item label="温度°C:" prop="tem1"  >
            <el-input v-model="form.tem1" style="width: 130px;" placeholder="示例:26.0"></el-input> 
        </el-form-item>
        <el-form-item label="湿度%:" prop="humidity1"  >
            <el-input v-model="form.humidity1" style="width: 130px;" placeholder="示例:80"></el-input> 
        </el-form-item>
        <el-form-item label="气压hPa:" prop="barometer1"  >
            <el-input v-model="form.barometer1" style="width: 130px;" placeholder="示例:1008.6"></el-input> 
        </el-form-item>
        <el-form-item label="风向°:" prop="windDir1" >
            <el-input v-model="form.windDir1" style="width: 130px;"  placeholder="示例:360"></el-input>
        </el-form-item>
        <el-form-item label="风速m/s:" prop="windSpeed1"  >
            <el-input v-model="form.windSpeed1" style="width: 130px;" placeholder="示例:1.6"></el-input> 
        </el-form-item>
        <el-form-item label="降水mm:" prop="rain1"  >
            <el-input v-model="form.rain1" style="width: 130px;" placeholder="示例:6.6"></el-input> 
        </el-form-item>
        <el-form-item label="光照w/m²:" prop="solarRadiation1" >
            <el-input v-model="form.solarRadiation1" style="width: 130px;"  placeholder="示例:260.1"></el-input> 
        </el-form-item>
        <!-- 下午 -->
        <el-divider content-position="center">下午</el-divider>
        <!-- 下午的观测时间 -->
        <div>
            <el-form-item label="观测时间:" prop="time1">
                <el-time-picker
                    v-model="form.time2"
                    :picker-options="{
                        selectableRange: '13:00:00 - 20:00:00'
                    }"
                    format="HH:mm:ss"
                    value-format="HH:mm:ss"
                    placeholder="选择观测时间"
                    @change="hanlderChange1"
                >
                </el-time-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="chooseshebei(2)">自动获取</el-button>
            </el-form-item>
        </div>
        <el-form-item label="温度°C:" prop="tem2">
            <el-input v-model="form.tem2" style="width: 130px;" placeholder="示例:26.0"></el-input> 
        </el-form-item>
        <el-form-item label="湿度%:" prop="humidity2">
            <el-input v-model="form.humidity2" style="width: 130px;" placeholder="示例:80"></el-input> 
        </el-form-item>
        <el-form-item label="气压hPa:" prop="barometer2">
            <el-input v-model="form.barometer2" style="width: 130px;" placeholder="示例:1008.8"></el-input> 
        </el-form-item>
        <el-form-item label="风向°:" prop="windDir2">
            <el-input v-model="form.windDir2" type="nubmer" style="width: 130px;" placeholder="示例:360"></el-input>
        </el-form-item>
        <el-form-item label="风速m/s:" prop="windSpeed2">
            <el-input v-model="form.windSpeed2" style="width: 130px;" placeholder="示例:1.5"></el-input> 
        </el-form-item>
        <el-form-item label="降水mm:" prop="rain2">
            <el-input v-model="form.rain2" style="width: 130px;" placeholder="示例:1.6"></el-input> 
        </el-form-item>
        <el-form-item label="光照w/m²:" prop="solarRadiation2">
            <el-input v-model="form.solarRadiation2" style="width: 130px;" placeholder="示例:365.1"></el-input> 
        </el-form-item>

        <!-- 昨日最高最低温 -->
        <el-divider content-position="center">昨日温度</el-divider>
        <el-form-item label="昨日最低温°C:" prop="minTem">
            <el-input v-model="form.minTem" style="width: 130px;"  placeholder="示例:26.0"></el-input> 
        </el-form-item>
        <el-form-item label="昨日最高温°C:" prop="maxTem" style="margin-left: 40px;">
            <el-input v-model="form.maxTem" style="width: 130px;"  placeholder="示例:26.0"></el-input> 
        </el-form-item>
        <div></div>
        <el-form-item label="最低温出现时间:" prop="minTemTime">
            <el-time-picker  
                v-model="form.minTemTime" 
                style="width: 130px;"  
                placeholder="示例:08:00"
                format="HH:mm"
                value-format="HH:mm"
            >
            </el-time-picker> 
        </el-form-item> 
        <el-form-item label="最高温出现时间:" prop="maxTemTime" style="margin-left: 40px;">
            <el-time-picker 
                v-model="form.maxTemTime" 
                style="width: 130px;"  
                placeholder="示例:08:00"
                format="HH:mm"
                value-format="HH:mm"
            >
            </el-time-picker> 
        </el-form-item> 

        <!-- 昨日最高最低湿度 -->
        <el-divider content-position="center">昨日湿度（非必填，自动观测站可选填）</el-divider>
        <el-form-item label="昨日最低湿度%:" prop="minHumidity">
            <el-input v-model="form.minHumidity" style="width: 130px;"  placeholder="示例:60"></el-input> 
        </el-form-item>
        <el-form-item label="昨日最高湿度%:" prop="maxHumidity" style="margin-left: 40px;">
            <el-input v-model="form.maxHumidity" style="width: 130px;"  placeholder="示例:80"></el-input> 
        </el-form-item>
        <div></div>
        <el-form-item label="最低湿度现时间:" prop="minHumidityTime">
            <el-time-picker 
                v-model="form.minHumidityTime" 
                style="width: 130px;"  
                placeholder="示例:08:00"
                format="HH:mm"
                value-format="HH:mm"
            >
            </el-time-picker> 
        </el-form-item> 
        <el-form-item label="最高湿度现时间:" prop="maxHumidityTime" style="margin-left: 40px;">
            <el-time-picker  
                v-model="form.maxHumidityTime" 
                style="width: 130px;"  
                placeholder="示例:08:00"
                format="HH:mm"
                value-format="HH:mm"
            ></el-time-picker> 
        </el-form-item> 
        <!-- 观测体会及最美瞬间 -->
        <el-divider content-position="center"></el-divider>
        <el-form-item label="观测体会:" prop="solarRadiation2">
            <el-input 
                type="textarea" 
                v-model="form.report" 
                style="width: 700px;" 
                :maxlength="200" 
                show-word-limit
                :rows="6"
            >
            </el-input>
        </el-form-item>
        <el-form-item label="最美瞬间:" prop="applyUrl">
            <el-upload
                class="upload-demo"
                :action="action"
                multiple
                :limit="1"
                :before-upload="handleBefore"
                :on-success="hanleSuccess"
                :file-list="fileList"
            >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2M</div>
            </el-upload>
        </el-form-item>
        <el-form-item label="备注:">
            <el-input 
                type="textarea" 
                v-model="form.remark" 
                style="width: 700px;" 
                :maxlength="100" 
                show-word-limit
                :rows="4"
            >
            </el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="flag = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
    </span>
    <chooseShebeiVue ref="choose"></chooseShebeiVue>
    </el-dialog>
</template>

<script>
import dayjs from "dayjs"
import $cookies from "vue-cookies"
import {BASE} from '@/services/base'
import { $activity } from "@/services"
import chooseShebeiVue from './chooseShebei.vue'
export default {
    components: {
        chooseShebeiVue
    },
    data() {
        return {
            flag:false,
            form:{},
            rules:{
                observer:[
                    { 
                        required: true, 
                        message: '请填写观测员', 
                        trigger: 'blur',
                    },
                ],
            },  
            action: BASE + '/server/common/upload',
            fileList:[], 
            showForm:true,
            isSubmit: true
        }
    },
    methods:{
        setFlag(row) {
            this.form = row
            this.flag = true
            this.form.date = dayjs().format('YYYY-MM-DD')
            this.form.userId = $cookies.get("userInfo").id 
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    $activity.reportData(this.form).then(res => {
                        if (res.code === 200) {
                            this.$message.success('上传成功')
                            this.$parent.getTableList() 
                            this.flag = false
                        } else {
                            this.$message.error(res.msg)
                            // this.flag = false
                        }
                    })
                }
            })
        },
        hanlderChange1(e) {
            console.log(e)
        },
        handleBefore(file) {
            if (file.size / 1024 / 1024 > 2) {
                this.$message.error('上传图片不能大于2M')
                this.fileList = []
                return
            }
        },
        hanleSuccess(response) {
            if(response.code === 200) {
                this.form.photos = response.data.url
            }
        },
        chooseshebei(type) {
            this.$refs.choose.setFlag(type)
        },
        hanlderChange(e) {
            console.log(e)
        },
        async autoData(type,device) {
            let params = {}
            params.device = device
            let currentHour = new Date().getHours()
            if ( currentHour >= 0 && currentHour < 12 ) {
                if (type === 1) {
                    params.time = dayjs().format('YYYYMMDDHHmmss')
                    this.form.time1 = dayjs().format('HH:mm:ss')
                } else if (type === 2) {
                    this.$message.error('还没到下午呢!')
                }
            } else {
                if (type === 1) {
                    params.time = dayjs().format('YYYYMMDD') + '090000'
                    this.form.time1 = dayjs().format('09:00:00')
                } else if (type === 2) {
                    params.time = dayjs().format('YYYYMMDDHHmmss')
                    this.form.time2 = dayjs().format('HH:mm:ss')
                }
            }
            this.showForm = false
            if (!this.isSubmit) return
            this.isSubmit = false
            let {code,data,msg} = await $activity.getAutoData(params)
            this.isSubmit = true
            if (code !== 200)  {
                this.$message.error(msg)
                this.showForm = true
                return
            }
            if (type === 1) {
                this.form.tem1 = data.tempf
                this.form.humidity1 = data.humidity
                this.form.barometer1 = data.baromabsin
                this.form.windDir1 = data.winddir
                this.form.windSpeed1 = data.windspeedmph
                this.form.rain1 = data.dailyrainin
                this.form.solarRadiation1 = data.solarradiation
                this.form.maxTem = data.maxTem
                this.form.minTem = data.minTem
                this.form.minTemTime = dayjs(data.minTemTime).format('HH:mm:ss')
                this.form.maxTemTime = dayjs(data.maxTemTime).format('HH:mm:ss')
                this.form.minHumidity = data.minHumidity
                this.form.maxHumidity = data.maxHumidity
                this.form.minHumidityTime = dayjs(data.minHumidityTime).format('HH:mm:ss')
                this.form.maxHumidityTime = dayjs(data.maxHumidityTime).format('HH:mm:ss')
            } else if (type === 2) {
                this.form.tem2 = data.tempf
                this.form.humidity2 = data.humidity
                this.form.barometer2 = data.baromabsin
                this.form.windDir2 = data.winddir
                this.form.windSpeed2 = data.windspeedmph
                this.form.rain2 = data.dailyrainin
                this.form.solarRadiation2 = data.solarradiation
                this.form.maxTem = data.maxTem
                this.form.minTem = data.minTem
                this.form.minTemTime = dayjs(data.minTemTime).format('HH:mm:ss')
                this.form.maxTemTime = dayjs(data.maxTemTime).format('HH:mm:ss')
                this.form.minHumidity = data.minHumidity
                this.form.maxHumidity = data.maxHumidity
                this.form.minHumidityTime = data.minHumidityTime
                this.form.maxHumidityTime = data.maxHumidityTime
            }
            this.showForm = true
        }
    }
}
</script>

<style>
.el-form-item__label {
    font-size: 18px;
}
.el-divider__text {
    font-size: 20px;
}
.el-input__inner {
    font-size: 18px;
}
</style>